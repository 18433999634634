import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Card from '../../../../../components/Card'
import Loading from '../../../../../components/Loading'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'
import { makeLoadSales } from '../../../../../main/factories/makeLoadSales'
import * as S from './style'

const loadSales = makeLoadSales()

enum PageStatus {
  LOADING,
  READY
}

export default function StoreSalesStatement (): ReactElement {
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING)
  const [data, setData] = useState<any>()

  const getIcon = useCallback((status: string) => {
    switch (status) {
      case 'APROVADO':
      case 'APPROVED':
        return <svg className="percentage-diff-icon icon-plus-small">
          <use xlinkHref="#svg-plus-small"></use>
        </svg>
      case 'REPROVADO':
        return <svg className="percentage-diff-icon icon-minus-small">
          <use xlinkHref="#svg-minus-small"></use>
        </svg>
      case 'AGUARDANDO':
        return <svg className="percentage-diff-icon icon-clock">
          <use xlinkHref="#svg-clock"></use>
        </svg>
    }
    return <svg className="percentage-diff-icon icon-cross" style={{ fill: '#C00' }}>
      <use xlinkHref="#svg-cross"></use>
    </svg>
  }, [])

  useEffect(() => {
    (async () => {
      setStatus(PageStatus.LOADING)
      const response = await loadSales.handle()
      setData(response.data)
      console.log(response.data)
      setStatus(PageStatus.READY)
    })()
  }, [])

  if (status === PageStatus.LOADING) return <Loading />

  return <>
    <SectionHeaderComponent
      pretitle="MINHA LOJA"
      title="Histórico de Resgates"
    />

    <S.ContentContainer>
      <Card>
        <S.DownloadButton onClick={() => { }}>Baixar Demonstrativo</S.DownloadButton>
      </Card>

      <S.TableContainer>
        <S.SalesTable>
          <thead>
            <tr>
              <th>DATA</th>
              <th>ITEM</th>
              <th>TIPO</th>
              <th>CÓDIGO</th>
              <th>PREÇO</th>
              <th>QUANTIDADE</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.produtos.map((prod: any) => <tr>
              <td>{prod.date}</td>
              <td>
                {prod.seller === 'viavarejo' && <Link to={`/order/${String(prod.code)}`}>{prod.name}</Link>}
                {!prod.seller && prod.type === 'Venda' && <Link to={'/perfil/store/downloads'}>{prod.name}</Link>}
                {prod.type !== 'Venda' && <>{prod.name}</>}
              </td>
              <td>{prod.type}</td>
              <td>{prod.code}</td>
              <td>$ {prod.price}</td>
              <td>{prod.amount}</td>
              <td>
                <S.IconContainer>{getIcon(prod.status)}</S.IconContainer>
              </td>
            </tr>)}
          </tbody>
        </S.SalesTable>
      </S.TableContainer>
    </S.ContentContainer>
  </>
}
