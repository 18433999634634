import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, .06);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 28px;
`

export const Filter = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const RankingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;

  h1 {
    text-align: center;
  }
`

export const Disclaimer = styled.span`
  font-size: 14px;
  text-align: center;
`

export const TableContainer = styled.div`
  overflow: auto;
`

export const Table = styled.table`
  font-family: Rajdhani;
  margin-inline: auto;

  th, td {
    text-align: center;
  }

  thead {
    tr {
      th {
        border: 0 none;
        border-bottom: 2px solid #E18719;
        padding: 0 12px 12px;
        text-transform: uppercase;
      }
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: 0 none;
      }
    }

    td {
      margin: 0 auto;
      border: 1px solid #E18719;
      border-collapse: collapse;
      padding: 6px 12px;
      white-space: nowrap;

      @media screen and (max-width: 520px) {
        font-size: 12px;
        padding: 3px 5px;
        white-space: normal;
      }

      &:first-child {
        border-left: 0 none;
      }

      &:last-child {
        border-right: 0 none;
      }
    }
  }
`

export const Row = styled.tr<{ $highlight?: boolean }>`
  background-color: ${({ $highlight }) => $highlight && '#FFC01A'};
`
